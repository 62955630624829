/**
 * ruleTypes: 规则类型
 * genRules： rule 生成函数
 * ctx： Vue实例
 */
import { genModels } from '@/plugins/widget/c-form/rules';
import roleTree from './roleTree';
export var setDisabled = function setDisabled() {
};
export var models = genModels(function (genRules, ctx) {
  return [
  {
    span: 12,
    kname: 'roleName',
    itemProps: {
      label: '角色名称：',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 6 } } },



  // {
  //   kname: 'status',
  //   itemProps: {
  //     label: '角色状态：',
  //     rules: [
  //       genRules('require')
  //     ]
  //   },
  //   component: {
  //     name: 'el-switch',
  //     props: {
  //       activeValue: 1,
  //       inactiveValue: 0
  //     }
  //   }
  // },
  {
    kname: 'auth',
    itemProps: {
      label: '角色权限：',
      rules: [genRules('require')] },

    component: {
      name: roleTree,
      props: {
        disableKeys: ctx.disableKeys } } }];




});

// form 默认值
export var formData = function formData() {return {};};